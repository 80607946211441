import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'


import '@/vant/vant'

//全局组件引用
import nav from '@/components/nav'
import breadcrumb from '@/components/breadcrumb'
import user from '@/components/user'
import tab from '@/components/tab'
import header_title from '@/components/header-title'
import Tag from '@/components/tag'
import button from '@/components/button'
//引入公共方法文件
import common from '@/common/api'
import getProvincial from '@/common/src/newArea'
Vue.prototype.$Provincial = getProvincial



//引入全局的变量
import global from '@/common/api/global'
Vue.prototype.$global = global 

import AddressParse, {AREA, Utils} from '@/common/src/parse/index';

Vue.prototype.$AddressParse = AddressParse 
Vue.prototype.$AREA = AREA  
Vue.prototype.$Utils = Utils  


//挂载axios
import axios from '@/api/axios';

import 'element-ui/lib/theme-chalk/index.css'

import './common/css/base.less'

//挂载到vue原型上 
Vue.prototype.$axios = axios  
Vue.prototype.$common = common  

Vue.use(ElementUI)

Vue.prototype.bus = new Vue()

    //注册组件
Vue.component('my-nav', nav)
Vue.component('my-breadcrumb', breadcrumb)
Vue.component('my-user', user)
Vue.component('header_title', header_title)
Vue.component('my-tab', tab)
Vue.component('my-Tag', Tag)
Vue.component('my-button', button) 

Vue.config.productionTip = false




Vue.mixin({
    data(){
        return{
            Tabloading : false,
            showAssociation:false
        }
    },
    mounted(){
        this.bus.$on(`${router.history.current.name}_TabLoding`,val=>{
            this.Tabloading = val
          })
    },
})

//全局自定义指令
Vue.directive('per',{
    inserted (el,data){
        if (data.value == '1231') {
            //设置不可点击
             //删除
            el.parentNode.removeChild(el)

          }

    }
})



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

