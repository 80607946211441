import axios from 'axios'
import router from '@/router'
import baseURL  from './baseUrl'
import NProgress from 'nprogress'
import store from '@/store'
import Vue from 'vue'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false }) // 取消 NProgress 的右上角螺旋加载提示

let  is404 = false 

import { Message ,MessageBox} from 'element-ui'

//1.创建axios 实例
 const instance = axios.create({
  //  //1.1.设置请求头路径
    baseURL,
    //1.2设置请求过期时间
    timeout:300000,
 })
let isTableLoding = true
 //2.设置请求拦截器
 instance.interceptors.request.use(config =>{
  //判断loding状态 是否为false 
  if(!isTableLoding){
    isTableLoding = true
    Vue.prototype.bus.$emit(`${router.history.current.name}_TabLoding`,isTableLoding)
  }
  NProgress.start() // 调用 NProgress 开始方法
  let token = localStorage.getItem('token')
  //判断是否是登录接口
  if(config.url!=='/api/login' || config.url!=='/api/dingding/order/detail' || config.url!=='/api/dingding/order/confirm' ||config.url !== '/api/dingding/customer/detail'){
    // 需要携带token 每一个请求请求头需要携带token
    config.headers.Authorization=`Bearer ${token}`;
  }
  return config
 },
 error=>{
   // 对请求错误做些什么
  //  alert('请求错误')
   return Promise.reject(error)
 })


 //3.响应拦截
 instance.interceptors.response.use( res=>{
            let stu = res.data
  if(res.config.url !== '/api/dingding/order/detail' && res.config.url !== '/api/dingding/order/confirm' && res.config.url !== '/api/dingding/customer/detail'){
          // 我这里是判断请求的状态码
            if( stu.status_code === 200){
              if(stu.data.code && stu.data.msg){
                 switch (stu.data.code) {
                  case 500:
                    Message.error(stu.data.msg)
                    break;

                  case 200:
                    Message.success(stu.data.msg) 
                    break;
                 }
              }
            }
            /**针对文件上传的时候 报错 避免提示 */
            else if(stu.status_code === 500 && stu.code != 100001){
              if(typeof stu.message == 'string'){
                Message.error(stu.message)
              }else if(typeof stu.message == 'object'){
                let str = ''
                for (const key in stu.message) {
                  str+= stu.message[key]
                }
                Message.error(str)
              }
            
            }else if(stu.code === 100001){
            let data = res.data.data
            
            //这个是表单错误信息 后端返回的
            if(data) {
                Object.keys(data).forEach(key=>{
                setTimeout(() => {
                  Message.error(data[key][0])
                }, 100);
              })
            }else{
              Message.error(res.data.message)
            }
            }
      }





      setTimeout(()=>{
        NProgress.done() // 调用 NProgress 关闭方法
        if(isTableLoding){
          isTableLoding = false
        }
        Vue.prototype.bus.$emit(`${router.history.current.name}_TabLoding`,isTableLoding)
      },0)
    
 return res //return后的值被组件中的请求的then方法的res接收

 
},err =>{
 
if(err.response !== undefined  ){
 const status = err.response.status

 if(is404 == false){
   if (status == 401) {
    if(!localStorage.getItem('isLoginOut'))//登出标记
    {
      //存入标识已经触发过 权限弹窗了 后面就不会触发  所以后面代码不能删除 名为isLoginOut
      localStorage.setItem('isLoginOut', true);//登出标记
    //请求登出接口
      MessageBox.confirm('登录已过期，请重新登录！', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => { 
        Message.success('退出登录!')
        router.push({path: '/login'});//返回登录页
        store.commit('clreatStroeData',1)
      })
      store.commit('clreatStroeData')
      is404 = true
    }
    }
  }
}
return Promise.reject(err)
})


 export default instance
